.bg-pattern-wrap .bg-size:before {
  padding-top: 20%;
}

.bg-patter {
    background-image: url(https://stormwater.pca.state.mn.us/images/f/f0/Water_gif_test.gif);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-color: #0b6faf;
    background-blend-mode: overlay;
    display: block;
}

/* .bg-pattern-wrap .bg-patter {
  background-color: #0baf9a;
} */
.login-page {
  padding-top: 25px;
  background-color: #fff;
  margin-top: -22px;
  border-radius: 22px 22px 0px 0px;
}

.login-page .logos {
  width: 60px;
  height: auto;
}

[class="dark"] .login-page .logos {
  display: none;
}

.login-page .logos.logos-w {
  display: none;
}

[class="dark"] .login-page .logos.logos-w {
  display: block;
}

.login-page > p {
  line-height: calc(20px + (25 - 20) * ((100vw - 320px) / (1920 - 320)));
  /* margin-top: 15px; */
}

.login-page > span {
  text-align: left;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: left;
  -ms-flex-pack: left;
  justify-content: left;
  margin-bottom: calc(24px + (40 - 34) * ((100vw - 320px) / (1920 - 320)));
}

[dir="rtl"] .login-page > span {
  -webkit-box-pack: right;
  -ms-flex-pack: right;
  justify-content: right;
}

.login-page > span a {
  text-decoration: underline;
  color: #777;
  margin-left: 10px;
}

[dir="rtl"] .login-page > span a {
  margin-right: 10px;
  margin-left: unset;
}

.login-page > span a svg {
  width: 21px;
  height: 21px;
}

.login-page .login-section .custom-form {
  text-align: center;
}

.login-page .login-section .custom-form h1 {
  margin-bottom: calc(6px + (10 - 6) * ((100vw - 320px) / (1920 - 320)));
  text-align: left;
}

[dir="rtl"] .login-page .login-section .custom-form h1 {
  text-align: right;
}

.login-page .login-section .custom-form p {
  text-align: left;
}

[dir="rtl"] .login-page .login-section .custom-form p {
  text-align: right;
}

.login-page .login-section .custom-form > span {
  text-align: left;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: left;
  -ms-flex-pack: left;
  justify-content: left;
  margin-bottom: calc(24px + (40 - 34) * ((100vw - 320px) / (1920 - 320)));
}

[dir="rtl"] .login-page .login-section .custom-form > span {
  -webkit-box-pack: right;
  -ms-flex-pack: right;
  justify-content: right;
}

.login-page .login-section .custom-form > span a {
  text-decoration: underline;
  color: #777;
  margin-left: 4px;
}

[dir="rtl"] .login-page .login-section .custom-form > span a {
  margin-right: 4px;
  margin-left: unset;
}

.login-page .login-section .custom-form > span a svg {
  width: 21px;
  height: 21px;
}

.login-page .login-section .custom-form .forgot {
  margin-top: -12px;
  text-align: right;
  display: block;
}

[dir="rtl"] .login-page .login-section .custom-form .forgot {
  text-align: left;
}

.login-page .login-section .custom-form .btn-solid {
  width: 100%;
  margin-bottom: 22px;
  font-weight: bold;
  font-size: calc(17px + (19 - 17) * ((100vw - 320px) / (1920 - 320)));
}

.login-page .login-section .socila-section {
  margin-top: 30px;
}

.login-page .login-section .socila-section > span {
  width: 100%;
  padding: 2px;
  text-align: center;
  display: block;
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(70%, #e8e8e8),
    color-stop(0%, rgba(255, 255, 255, 0))
  );
  background-image: linear-gradient(
    to right,
    #e8e8e8 70%,
    rgba(255, 255, 255, 0) 0%
  );
  background-position: bottom;
  background-size: 14px 1px;
  background-repeat: repeat-x;
}

.login-page .login-section .socila-section > span > span {
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 8px 10px;
  left: 50%;
}

.login-page .login-section .socila-section .social-wrap {
  margin-top: calc(30px + (50 - 30) * ((100vw - 320px) / (1920 - 320)));
}

.login-page .login-section .socila-section .social-wrap a {
  display: block;
  padding: 14px;
  border-radius: 5px;
  background-color: #fafafa;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  gap: 8px;
}

.login-page .login-section .socila-section .social-wrap a:not(:first-of-type) {
  margin-top: 15px;
}

.login-page .login-section .socila-section .social-wrap a img {
  width: 20px;
  height: auto;
}

.login-page .login-section .countdown {
  margin-bottom: calc(34px + (50 - 34) * ((100vw - 320px) / (1920 - 320)));
}

.login-page .login-section .countdown .otp-input {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 10px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.login-page .login-section .countdown .otp-input input {
  font-weight: 700;
  padding: 0;
  width: 25%;
  height: 50px;
  text-align: center;
}

.login-page
  .login-section
  .countdown
  .otp-input
  input::-webkit-input-placeholder {
  font-weight: 500;
}

.login-page .login-section .countdown .otp-input input::-moz-placeholder {
  font-weight: 500;
}

.login-page .login-section .countdown .otp-input input:-ms-input-placeholder {
  font-weight: 500;
}

.login-page .login-section .countdown .otp-input input::-ms-input-placeholder {
  font-weight: 500;
}

.login-page .login-section .countdown .otp-input input::placeholder {
  font-weight: 500;
}

.login-page .login-section .otp-countdown {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: calc(34px + (50 - 34) * ((100vw - 320px) / (1920 - 320)));
}

.login-page .login-section .otp-countdown a {
  text-decoration: underline;
  color: #777;
}
.font-md {
  font-size: calc(
    16px + (18 - 16) * ((100vw - 320px) / (1920 - 320))
  ) !important;
}
.fw-600 {
  font-weight: 600;
}
