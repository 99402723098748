:root {
  --mainColor: #144273;
  --backgroundColor: #ebebeb;
  --transparentColor: #fcebe5;
  --whiteColor: #ffffff;
  --blackColor: #111111;
  --paragraphColor: #777777;
  --fontSize: 16px;
  --card-title-fontSize: 24px;
  --transition: 0.5s;
}

.uni-banner-text h1 {
  color: #ffffff;
  margin-bottom: 5px;
  font-size: 60px;
  font-weight: 700;
}
.uni-banner-text ul {
  margin: 0px;
  padding: 0px;
  list-style: none;
}
.uni-banner-text ul li {
  display: inline-block;
  color: #144273;
  position: relative;
  margin-right: 20px;
  font-size: 16px;
}
.ptb-100 {
  padding-top: 100px;
  padding-bottom: 100px;
}
.about-inner-img {
  position: relative;
  overflow: hidden;
}
.pr-20 {
  padding-right: 20px;
}
.about-inner-img .inner-about-main-img {
  /* position: absolute; */
  width: 100%;
  height: 100%;
  z-index: 0;
  -webkit-animation: brightness-effect 5s infinite linear forwards,
    top-bottom 5s infinite linear forwards;
  animation: brightness-effect 5s infinite linear forwards,
    top-bottom 5s infinite linear forwards;
}

.about-inner-img .inner-about-shape {
  position: relative;
}
.default-section-title h6 {
  color: var(--mainColor);
  font-size: var(--fontSize);
  font-weight: 600;
}
.default-section-title h3 {
  color: var(--blackColor);
  margin-top: 10px;
  margin-bottom: 12px;
  font-size: 40px;
  font-weight: 700;
}
.about-card {
  /* padding-left: 90px; */
  margin-top: 25px;
  position: relative;
}
.about-card i {
  width: 70px;
  height: 70px;
  font-size: 35px;
  border-radius: 50%;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: #eeeeee;
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  position: absolute;
  left: 0;
}
.about-card h4 {
  margin-bottom: 10px;
  font-size: var(--card-title-fontSize);
  font-weight: 600;
}
.bg-fdfdfd {
  background-color: #b7daff;
}
.default-section-title-middle {
  max-width: 850px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
.default-section-title h6 {
  color: var(--mainColor);
  font-size: var(--fontSize);
  font-weight: 600;
}
default-section-title-middle h3 {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}
.default-section-title h3 {
  color: var(--blackColor);
  margin-top: 10px;
  margin-bottom: 12px;
  font-size: 40px;
  font-weight: 700;
}
.serviceBox {
  color: var(--main-color);
  font-family: "Gabarito", sans-serif;
  text-align: center;
  padding: 15px 28px 35px;
  position: relative;
  z-index: 0;
}
.serviceBox:before,
.serviceBox:after {
  content: "";
  border: 5px solid var(--main-color);
  border-bottom: none;
  position: absolute;
  left: 10px;
  right: 10px;
  top: 55px;
  bottom: 0;
  z-index: -1;
  clip-path: polygon(
    0 0,
    0 100%,
    100% 100%,
    100% 0,
    75% 0,
    75% 25%,
    25% 25%,
    25% 0
  );
}
.serviceBox:after {
  background-color: var(--main-color);
  height: 20px;
  border-radius: 350px 350px 0 0/50px 50px 0 0;
  border: none;
  top: auto;
  clip-path: none;
}
.serviceBox .service-icon {
  color: #222;
  font-size: 25px;
  line-height: 80px;
  height: 80px;
  width: 80px;
  margin: 0 auto 35px;
  border-radius: 50%;
  box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.5), 0 0 0 15px var(--main-color);
}
.serviceBox .title {
  color: #fff;
  background-color: var(--main-color);
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 12px 10px 9.5px;
  margin: 0 -28px 15px;
}
.serviceBox .description {
  color: #aaa;
  font-size: 14.5px;
  line-height: 22px;
  letter-spacing: 1px;
  margin: 0;
}
.serviceBox.green {
  --main-color: #00a0e3;
}
@media only screen and (max-width: 1199px) {
  .serviceBox {
    margin: 0 0 40px;
  }
}
