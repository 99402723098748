.range-slider {
    text-align: center;
    position: relative;
    height: 3em;
}

.range-slider input[type="range"] {
    position: absolute;
    left: 0;
    bottom: 0;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    padding: 0%;
    margin: 0%;
}

input[type="range"] {
    width: 100%;
}

input[type="range"]:focus {
    outline: none;
}

input[type="range"]::-webkit-slider-runnable-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    animation: 0.2s;
    background: #0051b8;
    font-family: "Poppins, sans-serif";
    border-radius: 10px;
    box-shadow: none;
    border: 0;
}

input {
    border: none;
    padding: 1px 1px;
}

input[type="range"]::-webkit-slider-thumb {
    z-index: 2;
    position: relative;
    box-shadow: none;
    border: none;
    height: 16px;
    width: 16px;
    background-color: #0051b8;
    border-radius: 25px;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -5px;
}

.ran {
  font-family: "Poppins, sans-serif";
  margin: 0px -13px;
  background: #f7f7f8;
  padding: 0px 0px;
}

.rangePrice{
  margin-bottom: 2px;
  padding-bottom: 10px;
  font-family: "Poppins, sans-serif";
  font-size: 16px;
  font-weight: 600;
  line-height: 1.6;
  color: #2b2b2d;
  text-transform: capitalize;
}
