@media only screen and (max-width: 767px) {
    input[type="checkbox"],
    .dropdown-content,
    input[type="radio"] {
        display: none;
    }

    .dropdown-wrapper.active .dropdown-content {
        display: block;
    }

    input[type="radio"] + label {
        cursor: pointer;
        padding-left: 35px;
        position: relative;
    }
    input[type="radio"] + label:before {
        left: 0;
        position: absolute;
        content: "";
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background: #dcdbe5;
    }

    /* input[type="radio"]:checked + label:before {
  left: -3px;
  background: #60c8ee;
  border: 2px solid #fff;
  -webkit-box-shadow: 0px 0px 0px 2px #53e0ed;
          box-shadow: 0px 0px 0px 2px #53e0ed;
} */

    body {
        font-family: sans-serif;
    }

    .dropdown-wrapper {
        margin: 0 auto;
        max-width: 260px;
        width: 100%;
    }

    .dropdown-content {
        border-radius: 5px;
        position: relative;
        border: 1px solid #b3b3bf;
        padding: 15px;
        left: 37%;
        top: 12px;
        transform: translate(-50%, 0);
        width: 22rem;
    }
    .dropdown-content ul,
    .dropdown-content li {
        margin: 0;
        padding: 0;
    }
    .dropdown-content ul {
        list-style: none;
    }
    .dropdown-content:after,
    .dropdown-content:before {
        bottom: 100%;
        left: 25%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
    }
    .dropdown-content:after {
        border-color: rgba(0, 0, 0, 0);
        border-bottom-color: #ffff;
        border-width: 10px;
        margin-left: -10px;
    }
    .dropdown-content:before {
        border-color: rgba(179, 179, 191, 0);
        border-bottom-color: #b3b3bf;
        border-width: 11px;
        margin-left: -11px;
    }

    .icon-wrapper {
        color: #b3b3bf;
    }
    .icon-wrapper i {
        position: relative;
        margin-left: 19px;
        width: 38px;
        height: 38px;
        background: #dcdbe5;
        border-radius: 50%;
        text-align: center;
        display: block;
        cursor: pointer;
        color: #2f255b;
    }
    .icon-wrapper i:before {
        display: block;
        line-height: 35px;
        font-size: 15px;
    }
    .icon-wrapper li {
        /* border-bottom: 1px solid #dcdbe5; */
        padding-bottom: 15px;
        max-height: 32px;
        font-size: 18px;
        margin-top: 15px;
        color: #2f255b;
        &:last-child {
            border-bottom: 0;
            padding-bottom: 0;
        }
    }
    .icon-wrapper li:first-child {
        margin-top: 0;
    }
    .icon-wrapper li span {
        display: block;
        padding-left: 35px;
        font-size: 0.9em;
        color: #b3b3bf;
    }

    .icon-wrapper {
        display: inline-block;
        position: relative;
    }

    .dropdown-heading {
        color: #b3b3bf;
    }

    .fil {
        font-family: "Poppins, sans-serif";
        font-size: 20px;
        font-weight: bold;
        color: #00000094;
        margin-left: -43px;
    }
    h5{
        color: #000;
        font-weight: 600;
    }
    label{
        font-family: "Poppins, sans-serif";
        font-size: 14px;
        color: #7a7a7a;
        text-transform: capitalize;
    }
    .inBox{
        padding: 24px;
        background-color: #f7f7f8;
        border: 1px solid #e9e9e9;
        border-radius: 5px;
        position: sticky;
        top: 30px;
      }   

      .lne{
        margin-bottom: 2px;
        padding-bottom: 10px;
        font-family: "Poppins, sans-serif";
        font-size: 16px;
        font-weight: 500;
        line-height: 1.6;
        color: #2b2b2d;
        text-transform: capitalize;
        border-bottom: 1px solid #e9e9e9;
      }
      
      .wef{
        padding: 0 2rem 0 0.8rem;
        font-family: "Poppins, sans-serif";
        font-size: 15px;
        background-size: 10px;
        line-height: 1.2;
        color: #7a7a7a;
        width: auto;
        border: none;
        cursor: pointer;
        -webkit-box-shadow: none;
        box-shadow: none;
      }
      
      .weFne{
        display: flex;
        justify-content: space-between;
        margin-bottom: 30px;
        flex-wrap: wrap;
        -webkit-box-align: center;
        align-items: center;
        padding: 2px 8px;
        background-color: #f7f7f8;
        border: 1px solid #e9e9e9;
        border-radius: 5px;
      }
      
      .fnt{
        font-family: "Poppins, sans-serif";
      }
      
      .top{
        border-top: 1px solid #f1f1f1;
      }
      
      .lbl{
        height: 35px;
        margin: 5px;
        padding: 3px 0px 3px 15px;
        background-color: #fff;
        border: 1px solid #e9e9e9;
        border-radius: 5px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
      }
}
