.payment-page
  .payment-section
  .accordions
  .accordions-item:first-of-type
  .accordions-header {
  border-top: none;
}

.payment-page .payment-section .accordions .accordions-item .accordions-header {
  padding: 15px 0;
}

.payment-page .payment-section .accordions .accordions-item .accordions-button {
  font-weight: 600;
}

.payment-page
  .payment-section
  .accordions
  .accordions-item
  .accordions-button::after {
  background-color: #edf8f8;
  width: calc(20px + (24 - 20) * ((100vw - 320px) / (1920 - 320)));
  height: calc(20px + (24 - 20) * ((100vw - 320px) / (1920 - 320)));
  border-radius: 100%;
  background-position: center;
}

[class="dark"]
  .payment-page
  .payment-section
  .accordions
  .accordions-item
  .accordions-button::after {
  background-color: #f0f0f0;
  -webkit-filter: invert(1);
  filter: invert(1);
}

.payment-page .payment-section .accordions .accordions-item .accordions-body {
  margin-top: 0;
  padding-bottom: 20px;
}

.payment-page
  .payment-section
  .accordions
  .accordions-item
  .accordions-body.net-banking
  .row
  > div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media only screen and (max-width: 567px) {
  .payment-page
    .payment-section
    .accordions
    .accordions-item
    .accordions-body.net-banking
    .row
    > div {
    width: 100%;
  }
}

.payment-page
  .payment-section
  .accordions
  .accordions-item
  .accordions-body
  .filter-row
  .filter-col {
  border: 1px solid #f1f1f1;
  background-color: #fafafa;
  padding: 11px 11px;
  font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
  border-radius: 5px;
  font-weight: 600;
  color: #222;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 10px;
}

.payment-page
  .payment-section
  .accordions
  .accordions-item
  .accordions-body
  .filter-row
  .filter-col:not(:first-of-type) {
  margin-top: 14px;
}

.payment-page
  .payment-section
  .accordions
  .accordions-item
  .accordions-body
  .filter-row
  .filter-col
  .check {
  padding: 2px 8px 4px;
  border-radius: 0px 5px 0px 5px;
  background-color: #0baf9a;
  -webkit-transform: scale(0);
  transform: scale(0);
  position: absolute;
  right: -4px;
  top: -3px;
}

[dir="rtl"]
  .payment-page
  .payment-section
  .accordions
  .accordions-item
  .accordions-body
  .filter-row
  .filter-col
  .check {
  left: -4px;
  right: unset;
  border-radius: 5px 0px 5px 0px;
}

.payment-page
  .payment-section
  .accordions
  .accordions-item
  .accordions-body
  .filter-row
  .filter-col
  .check
  img {
  width: 15px;
  height: 15px;
}

.payment-page
  .payment-section
  .accordions
  .accordions-item
  .accordions-body
  .filter-row
  .filter-col
  .payment-card {
  width: calc(40px + (50 - 40) * ((100vw - 320px) / (1920 - 320)));
  height: auto;
}

[class="dark"]
  .payment-page
  .payment-section
  .accordions
  .accordions-item
  .accordions-body
  .filter-row
  .filter-col
  .discovery {
  display: none;
}

.payment-page
  .payment-section
  .accordions
  .accordions-item
  .accordions-body
  .filter-row
  .filter-col
  .discovery-w {
  display: none;
}

[class="dark"]
  .payment-page
  .payment-section
  .accordions
  .accordions-item
  .accordions-body
  .filter-row
  .filter-col
  .discovery-w {
  display: block;
}

.payment-page
  .payment-section
  .accordions
  .accordions-item
  .accordions-body
  .filter-row
  .filter-col.active {
  border-color: #0baf9a;
}

.payment-page
  .payment-section
  .accordions
  .accordions-item
  .accordions-body
  .filter-row
  .filter-col.active
  .check {
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.payment-page
  .payment-section
  .accordions
  .accordions-item
  .accordions-body
  .input-box {
  margin-bottom: calc(8px + (12 - 8) * ((100vw - 320px) / (1920 - 320)));
}

.payment-page
  .payment-section
  .accordions
  .accordions-item
  .accordions-body
  .input-box
  label {
  font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
}

.payment-page
  .payment-section
  .accordions
  .accordions-item
  .accordions-body.cash
  .filter-row
  .filter-col {
  font-weight: normal !important;
}

.payment-page .order-detail {
  background-color: #fafafa;
  padding: calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320)));
  border-radius: 18px;
}

.payment-page .order-detail ul li {
  padding: 2px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.payment-page .order-detail ul li span {
  font-size: calc(15px + (18 - 15) * ((100vw - 320px) / (1920 - 320)));
  border: none;
  color: #777;
}

.payment-page .order-detail ul li:last-child {
  padding: 10px 0;
  font-weight: 600;
  margin-top: 8px;
  border-top: 1px solid #f1f1f1;
  margin-bottom: -5px;
}

.payment-page .order-detail ul li:last-child span {
  color: #222;
}

.payment-page .order-detail h3 {
  margin-bottom: 6px;
}

.payment-page .order-detail ul li:last-of-type {
  padding-bottom: 0;
}
.accordions .accordions-item {
  border: none;
  background-color: #fff !important;
}

.accordions .accordions-item .accordions-header {
  padding: 10px 0;
  border-top: 1px solid #f1f1f1;
  background-color: #fff;
}

.accordions .accordions-item .accordions-button {
  background-color: #fff;
  color: #222;
  line-height: 23px;
  padding: 0;
  border: none;
  font-weight: 600;
  font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
}

.accordions .accordions-item .accordions-button:focus {
  color: #222;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: none;
}

.accordions .accordions-item .accordions-header .accordions-button::after {
  background: url("../../public/assets/icons/svg/chevron-right.svg");
  z-index: 100;
}

.accordions .accordions-item .accordions-button:not(.collapsed) {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.accordions .accordions-item .accordions-button:not(.collapsed)::after {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.accordions .accordions-item .accordions-body {
  padding: 0 0 10px 0;
  margin-top: -5px;
  background-color: #fff;
}

.accordions .accordions-item .accordions-body p {
  margin-bottom: 0;
}

.payment-page
  .payment-section
  .accordions
  .accordions-item
  .accordions-button::after {
  background-color: #edf8f8;
  width: calc(20px + (24 - 20) * ((100vw - 320px) / (1920 - 320)));
  height: calc(20px + (24 - 20) * ((100vw - 320px) / (1920 - 320)));
  border-radius: 100%;
  background-position: center;
}

.accordions .accordions-item .accordions-button:not(.collapsed) i {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
}
