.mb-xxl {
  margin-bottom: calc(75px + (100 - 75) * ((100vw - 320px) / (1920 - 320)));
}

.nav-tab,
.nav-tab2 {
  margin-left: -15px;
  margin-right: -15px;
  padding: 10px 15px;
  background-color: #fafafa;
  margin-bottom: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  overflow-x: hidden;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  gap: 15px;
}

.nav-tab:hover,
.nav-tab2:hover {
  overflow-x: auto;
}

@media only screen and (max-width: 767px) {
  .nav-tab,
  .nav-tab2 {
    overflow-x: auto;
  }
}

.nav-tab .nav-link,
.nav-tab2 .nav-link {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  padding: 0;
  background-color: transparent;
  color: #222;
  font-weight: 600;
  font-size: calc(15px + (18 - 15) * ((100vw - 320px) / (1920 - 320)));
}

.nav-tab .nav-link.active,
.nav-tab2 .nav-link.active {
  color: #0baf9a !important;
  background-color: transparent;
}

.nav-tab2 {
  padding: 0;
  gap: 0;
}

.nav-tab2 .nav-item .nav-link {
  border-radius: 0;
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;
  padding: 6px calc(10px + (15 - 10) * ((100vw - 320px) / (1920 - 320)));
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  font-weight: normal;
}

.nav-tab2 .nav-item .nav-link.active {
  border-bottom-color: #0baf9a;
}

.pillsFont button {
  font-family: "Mulish", sans-serif;
  border: none !important;
  outline: none !important;
}

.shop-page .search-boxs {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 15px;
  margin-bottom: calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320)));
}

.shop-page .search-boxs input[type="search" i]::-webkit-search-cancel-button {
  display: none !important;
}

.shop-page .search-boxs i {
  position: absolute;
  font-size: calc(20px + (24 - 20) * ((100vw - 320px) / (1920 - 320)));
  top: 50%;
  cursor: pointer;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.shop-page .search-boxs .search {
  left: 16px;
  font-size: 21px;
}

.shop-page .search-boxs .mic {
  right: 16px;
  font-size: 23px;
}

.shop-page .search-boxs input {
  font-size: calc(15px + (17 - 15) * ((100vw - 320px) / (1920 - 320)));
  padding: 10px 47px;
  border-radius: 4px;
  color: #222;
  border: 1px solid #ceefeb;
  background-color: #fafafa;
}

.shop-page .search-boxs input::-webkit-input-placeholder {
  color: #cacaca;
}

.shop-page .search-boxs input::-moz-placeholder {
  color: #cacaca;
}

.shop-page .search-boxs input:-ms-input-placeholder {
  color: #cacaca;
}

.shop-page .search-boxs input::-ms-input-placeholder {
  color: #cacaca;
}

.shop-page .search-boxs input::placeholder {
  color: #cacaca;
}

.shop-page .search-boxs input:focus {
  border: 1px solid #ceefeb;
  background-color: #fafafa;
  color: #222;
}

.shop-page .search-boxs > div {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.shop-page .search-boxs .filter {
  border: none;
  outline: none;
  background-color: transparent;
  color: #0b69af;
  font-weight: 600;
  font-size: 20px;
  font-family: "Mulish";
}

.shop-page .tab-content .tab-pane .media {
  background-color: #fafafa !important;
}

.shop-page .media {
  background-color: #fafafa !important;
}

.shop-page .tab-content .tab-pane .media .media-body > a {
  font-weight: 600;
}

.shop-page .tab-content .tab-pane .bg-size:before {
  padding-top: 0;
}

.shop-page .tab-content .tab-pane .banner {
  position: relative;
  margin: calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320))) -15px;
}

.shop-page .tab-content .tab-pane .banner h2 {
  padding: 0 44px;
  position: absolute;
  font-weight: 600;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  font-family: "Quicksand", "sans-serif";
  font-size: calc(18px + (22 - 18) * ((100vw - 320px) / (1920 - 320)));
}

[class="dark"] .shop-page .tab-content .tab-pane .banner h2 {
  color: #222;
}

.font-sms {
  padding-left: 12px;
}

.plus-minus {
  border: 1px solid rgba(11, 175, 154, 0.09);
  border-radius: 5px;
  padding: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
  text-align: center;
  font-family: "Mulish";
  font-weight: 900;
  border: 1px solid #0b69af;
}

.plus-minus input {
  background-color: #fff;
  color: #0baf9a;
  border: none;
  font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
  outline: none;
  width: 35px;
  text-align: center;
}

.plus-minus .sub,
.plus-minus .add {
  width: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320)));
  height: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320)));
  cursor: pointer;
}

/* fotter */

.footer-wrap.shop {
  padding-left: 15px;
  padding-right: 15px;
  max-width: calc(100vw - 30px);
  border-radius: 4px;
  bottom: calc(10px + (15 - 10) * ((100vw - 320px) / (1920 - 320)));
  padding: calc(6px + (9 - 6) * ((100vw - 320px) / (1920 - 320))) 10px;
}

@media only screen and (min-width: 767px) {
  .footer-wrap.shop {
    max-width: calc(
      600px - calc(20px + (30 - 20) * ((100vw - 320px) / (1920 - 320)))
    );
  }
}

.footer-wrap.shops .footers {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-top: 0;
}

.footer-wrap.shops .footer-item {
  opacity: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.footer-wrap.shops .footer .footer-item > span {
  color: #fff;
  font-family: "Mulish";
}

.footer-wrap.shops .footer .footer-item > span:last-of-type {
  font-weight: 600;
}

.footer-wrap.shops .footer .footer-item > a {
  font-weight: 600;
  color: #fff;
  line-height: 21px;
  margin-right: -7px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  font-family: "Mulish";
  align-items: center;
  gap: calc(5px + (10 - 5) * ((100vw - 320px) / (1920 - 320)));
}

@media only screen and (max-width: 400px) {
  .footer-wrap.shops .footer .footer-item > a {
    font-size: 14px !important;
  }
}

[dir="rtl"] .footer-wrap.shop .footer .footer-item > a {
  margin-left: -7px;
  margin-right: unset;
}

.footer-wrap.shops .footer .footer-item > a svg {
  margin-bottom: -2.2px;
  width: calc(22px + (28 - 22) * ((100vw - 320px) / (1920 - 320)));
  height: calc(22px + (28 - 22) * ((100vw - 320px) / (1920 - 320)));
}

[dir="rtl"] .footer-wrap.shops .footer .footer-item > a svg {
  -webkit-transform-origin: center;
  transform-origin: center;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
