cart-page .cart-item-wrap .swipe-to-show {
  overflow-x: hidden;
  -ms-touch-action: none;
  touch-action: none;
  position: relative;
}

.cart-page .cart-item-wrap .swipe-to-show:not(:first-of-type) {
  margin-top: 15px;
}

.cart-page .cart-item-wrap .swipe-to-show .media {
  width: 100%;
  background-color: #fafafa;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  position: relative;
  z-index: 1;
}

[class="dark"]
  .cart-page
  .cart-item-wrap
  .swipe-to-show
  .media
  .media-body
  span
  .badges-round {
  color: #f0f0f0;
}

.cart-page .cart-item-wrap .swipe-to-show .delete-button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  cursor: pointer;
  background-color: #0baf9a;
  padding: 14px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 0 10px 10px 0;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  position: absolute;
  top: 0;
  bottom: 0;
  right: -60px;
  z-index: 2;
}

[dir="rtl"] .cart-page .cart-item-wrap .swipe-to-show .delete-button {
  right: unset;
  border-radius: 10px 0 0 10px;
  left: -60px;
}

.cart-page .cart-item-wrap .swipe-to-show .delete-button svg {
  width: calc(20px + (24 - 18) * ((100vw - 320px) / (1920 - 320)));
  height: calc(20px + (24 - 18) * ((100vw - 320px) / (1920 - 320)));
  stroke: #fff;
}

[class="dark"] .cart-page .cart-item-wrap .swipe-to-show .delete-button svg {
  stroke: #f0f0f0;
}

.cart-page .cart-item-wrap .swipe-to-show.active .media {
  margin-left: -40px;
}

[dir="rtl"] .cart-page .cart-item-wrap .swipe-to-show.active .media {
  margin-right: -40px;
  margin-left: unset;
}

.cart-page .cart-item-wrap .swipe-to-show.active .delete-button {
  right: 0;
}

[dir="rtl"] .cart-page .cart-item-wrap .swipe-to-show.active .delete-button {
  left: 0;
  right: unset;
}

.cart-page .coupon-ticket-wrap {
  margin-top: 20px;
}

.cart-page .coupon-ticket-wrap .coupon-ticket {
  cursor: pointer;
  background-color: #edf8f8;
  padding: 20px;
  position: relative;
  font-family: "Quicksand", "sans-serif";
}

.cart-page .coupon-ticket-wrap .coupon-ticket .media {
  margin-top: calc(-3px + (-7 + 3) * ((100vw - 320px) / (1920 - 320)));
  background-color: transparent;
  gap: calc(6px + (15 - 6) * ((100vw - 320px) / (1920 - 320)));
  padding: 0;
}

.cart-page .coupon-ticket-wrap .coupon-ticket .media .off {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 4px;
}

@media only screen and (max-width: 375px) {
  .cart-page .coupon-ticket-wrap .coupon-ticket .media .off {
    margin-top: -20px;
  }
}

.cart-page .coupon-ticket-wrap .coupon-ticket .media .off > span:first-of-type {
  font-size: calc(26px + (45 - 26) * ((100vw - 320px) / (1920 - 320)));
  font-weight: 700;
  color: #0baf9a;
}

.cart-page .coupon-ticket-wrap .coupon-ticket .media .off > span:last-of-type {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.cart-page
  .coupon-ticket-wrap
  .coupon-ticket
  .media
  .off
  > span:last-of-type
  span {
  font-size: calc(14px + (20 - 14) * ((100vw - 320px) / (1920 - 320)));
  font-weight: normal;
  color: #0baf9a;
}

.cart-page
  .coupon-ticket-wrap
  .coupon-ticket
  .media
  .off
  > span:last-of-type
  span:first-of-type {
  margin-bottom: calc(-6px + (-10 + 6) * ((100vw - 320px) / (1920 - 320)));
  font-size: calc(17px + (24 - 17) * ((100vw - 320px) / (1920 - 320)));
}

.cart-page .coupon-ticket-wrap .coupon-ticket .media .media-body {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

@media only screen and (max-width: 375px) {
  .cart-page .coupon-ticket-wrap .coupon-ticket .media .media-body {
    position: absolute;
    left: 20px;
    bottom: 18px;
  }

  [dir="rtl"] .cart-page .coupon-ticket-wrap .coupon-ticket .media .media-body {
    left: unset;
    right: 20px;
  }
}

.cart-page .coupon-ticket-wrap .coupon-ticket .media .media-body h2,
.cart-page .coupon-ticket-wrap .coupon-ticket .media .media-body span {
  font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
}

.cart-page .coupon-ticket-wrap .coupon-ticket .media .media-body h2 {
  line-height: calc(18px + (22 - 18) * ((100vw - 320px) / (1920 - 320)));
  margin-bottom: 0;
}

@media only screen and (max-width: 375px) {
  .cart-page .coupon-ticket-wrap .coupon-ticket .media .media-body {
    text-align: center;
  }

  .cart-page .coupon-ticket-wrap .coupon-ticket .media .media-body h2,
  .cart-page .coupon-ticket-wrap .coupon-ticket .media .media-body span {
    font-size: 16px;
  }

  .cart-page .coupon-ticket-wrap .coupon-ticket .media .media-body span {
    display: none;
  }

  .cart-page .coupon-ticket-wrap .coupon-ticket .media .media-body h2 {
    margin-bottom: 0;
    line-height: 22px;
  }
}

.cart-page .coupon-ticket-wrap .coupon-ticket .code {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 6px;
}

@media only screen and (max-width: 375px) {
  .cart-page .coupon-ticket-wrap .coupon-ticket .code {
    margin-left: auto;
  }

  [dir="rtl"] .cart-page .coupon-ticket-wrap .coupon-ticket .code {
    margin-right: auto;
    margin-left: unset;
  }
}

.cart-page .coupon-ticket-wrap .coupon-ticket .code span {
  font-weight: 400;
  color: #222;
  font-size: calc(12px + (16 - 12) * ((100vw - 320px) / (1920 - 320)));
}

.cart-page .coupon-ticket-wrap .coupon-ticket .code a {
  font-size: calc(12px + (16 - 12) * ((100vw - 320px) / (1920 - 320)));
  padding: calc(3px + (5 - 3) * ((100vw - 320px) / (1920 - 320)))
    calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));
  line-height: 22px;
  background-color: #0baf9a;
  color: #fff;
  border-radius: 100px;
}

[class="dark"] .cart-page .coupon-ticket-wrap .coupon-ticket .code a {
  color: #f0f0f0;
}

@media only screen and (max-width: 375px) {
  .cart-page .coupon-ticket-wrap .coupon-ticket .code a {
    font-size: 12px;
    padding: 3px 14px;
  }
}

.cart-page .coupon-ticket-wrap .coupon-ticket .big-circle {
  position: absolute;
  top: 50%;
  right: 24%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

[dir="rtl"] .cart-page .coupon-ticket-wrap .coupon-ticket .big-circle {
  right: unset;
  left: 20%;
}

@media only screen and (max-width: 375px) {
  .cart-page .coupon-ticket-wrap .coupon-ticket .big-circle {
    right: 30%;
  }

  [dir="rtl"] .cart-page .coupon-ticket-wrap .coupon-ticket .big-circle {
    left: 30%;
    right: unset;
  }
}

.cart-page .coupon-ticket-wrap .coupon-ticket .big-circle span {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background-color: transparent;
  display: block;
  position: relative;
}

@media only screen and (max-width: 375px) {
  .cart-page .coupon-ticket-wrap .coupon-ticket .big-circle span {
    width: 30px;
    height: 30px;
  }
}

.cart-page .coupon-ticket-wrap .coupon-ticket .big-circle span::before,
.cart-page .coupon-ticket-wrap .coupon-ticket .big-circle span::after {
  content: "";
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: inherit;
  height: inherit;
  border-radius: inherit;
  background-color: #fff;
  position: absolute;
}

.cart-page .coupon-ticket-wrap .coupon-ticket .big-circle span::before {
  top: -50px;
}

.cart-page .coupon-ticket-wrap .coupon-ticket .big-circle span::after {
  bottom: -50px;
}

@media only screen and (max-width: 375px) {
  .cart-page .coupon-ticket-wrap .coupon-ticket .big-circle span::before {
    top: -45px;
  }

  .cart-page .coupon-ticket-wrap .coupon-ticket .big-circle span::after {
    bottom: -45px;
  }
}

.cart-page .coupon-ticket-wrap .coupon-ticket .circle-5 {
  top: 32%;
}

.cart-page .coupon-ticket-wrap .coupon-ticket .circle-5.left {
  left: -6px;
}

.cart-page .coupon-ticket-wrap .coupon-ticket .circle-5.right {
  right: -6px;
}

.cart-page .order-detail h3 {
  margin-bottom: 10px;
}

.cart-page .order-detail ul li {
  padding: 2px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.cart-page .order-detail ul li span {
  font-size: calc(15px + (18 - 15) * ((100vw - 320px) / (1920 - 320)));
  border: none;
  color: #777;
  font-family: 'mulish';
}

.cart-page .order-detail ul li:last-child {
  padding: 10px 0;
  font-weight: 600;
  margin-top: 8px;
  border-top: 1px solid #f1f1f1;
  margin-bottom: -5px;
}

.cart-page .order-detail ul li:last-child span {
  color: #222;
}

.title-2 {
    font-weight: 600;
    font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
    font-family: 'Mulish';
}
ul {
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 0;
}