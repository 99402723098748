.footer-wrap {
  margin: 0 auto;
  max-width: 767px;
  position: fixed;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  bottom: 0;
  left: 0;
  right: 0;
  padding: calc(10px + (15 - 10) * ((100vw - 320px) / (1920 - 320))) 0;
  background-color: #0051b8;
  z-index: 3;
}
@media only screen and (min-width: 767px) {
  .footer-wrap {
    max-width: 600px;
  }
}
.footer-wrap .footer {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 8px;
  /* margin-left: -40px; */
}
.footer-wrap .footer .footer-item .footer-link {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2px;
  position: relative;
}
.footer-wrap .footer .footer-item .footer-link i {
  font-size: 25px;
  color: #f5f5f5;
}
.footer-wrap .footer .footer-item .footer-link .icon {
  width: calc(30px + (32 - 30) * ((100vw - 320px) / (1920 - 320)));
  height: calc(30px + (32 - 30) * ((100vw - 320px) / (1920 - 320)));
  /* margin-bottom: -7px; */
}
.footer-wrap .footer .footer-item .footer-link .offer {
  margin-top: -7px;
}
.footer-wrap .footer .footer-item .footer-link span {
  color: #f5f5f5;
  /* margin-bottom: -3px; */
  font-size: calc(12px + (15 - 12) * ((100vw - 320px) / (1920 - 320)));
}
.footer-wrap .footer .footer-item .footer-link::after {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 4px;
  height: 4px;
  display: block;
  background-color: #f5f5f5;
  border-radius: 100%;
  top: -8px;
  opacity: 0;
  transition: all 0.4s;
}
.footer-wrap .footer .footer-item.active .footer-link::after,
.footer-wrap .footer .footer-item:hover .footer-link::after {
  opacity: 1;
}
.footer-wrap.shop {
  padding-left: 15px;
  padding-right: 15px;
  max-width: calc(100vw - 30px);
  border-radius: 4px;
  bottom: calc(10px + (15 - 10) * ((100vw - 320px) / (1920 - 320)));
  padding: calc(6px + (9 - 6) * ((100vw - 320px) / (1920 - 320))) 10px;
}
@media only screen and (min-width: 767px) {
  .footer-wrap.shop {
    max-width: calc(
      600px - calc(20px + (30 - 20) * ((100vw - 320px) / (1920 - 320)))
    );
  }
}
.footer-wrap.shop .footer {
  justify-content: space-between;
  margin-top: 0;
}
.footer-wrap.shop .footer .footer-item {
  opacity: 1;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}
.footer-wrap.shop .footer .footer-item > span {
  color: #f5f5f5;
}
.footer-wrap.shop .footer .footer-item > span:last-of-type {
  font-weight: 600;
}
.footer-wrap.shop .footer .footer-item > a {
  font-weight: 600;
  color: #f5f5f5;
  line-height: 21px;
  margin-right: -7px;
  display: flex;
  align-items: center;
  gap: calc(5px + (10 - 5) * ((100vw - 320px) / (1920 - 320)));
}
@media only screen and (max-width: 400px) {
  .footer-wrap.shop .footer .footer-item > a {
    font-size: 14px !important;
  }
}
[dir="rtl"] .footer-wrap.shop .footer .footer-item > a {
  margin-left: -7px;
  margin-right: unset;
}
.footer-wrap.shop .footer .footer-item > a svg {
  margin-bottom: -2.2px;
  width: calc(22px + (28 - 22) * ((100vw - 320px) / (1920 - 320)));
  height: calc(22px + (28 - 22) * ((100vw - 320px) / (1920 - 320)));
}
[dir="rtl"] .footer-wrap.shop .footer .footer-item > a svg {
  transform-origin: center;
  transform: rotate(180deg);
}
.footer-wrap.footer-button {
  padding-left: calc(10px + (15 - 10) * ((100vw - 320px) / (1920 - 320)));
  padding-right: calc(10px + (15 - 10) * ((100vw - 320px) / (1920 - 320)));
  max-width: calc(
    100vw - calc(20px + (30 - 20) * ((100vw - 320px) / (1920 - 320)))
  );
  border-radius: 4px;
  text-align: center;
  bottom: calc(10px + (15 - 10) * ((100vw - 320px) / (1920 - 320)));
}
@media only screen and (min-width: 767px) {
  .footer-wrap.footer-button {
    max-width: calc(
      600px - calc(20px + (30 - 20) * ((100vw - 320px) / (1920 - 320)))
    );
  }
}
.footer-wrap.footer-button a {
  width: 100%;
  display: block;
  color: #f5f5f5;
  font-weight: 600;
}
