.error-404 .banner-box,
.empty-cart .banner-box,
.no-order .banner-box {
  width: 85%;
  margin: 0 auto;
  margin-top: calc(70px + (90 - 70) * ((100vw - 320px) / (1920 - 320)));
  text-align: center;
  position: relative;
}

.error-404 .banner-box img,
.empty-cart .banner-box img,
.no-order .banner-box img {
  width: 70%;
  position: relative;
}

@media (max-width: 767px) {
  .error-404 .banner-box img,
  .empty-cart .banner-box img,
  .no-order .banner-box img {
    width: 80%;
  }
}

.error-404 .error,
.empty-cart .error,
.no-order .error {
  text-align: center;
}

.error-404 .error h2,
.empty-cart .error h2,
.no-order .error h2 {
  font-weight: 600;
  position: relative;
  margin-bottom: calc(25px + (30 - 25) * ((100vw - 320px) / (1920 - 320)));
  font-family: "Quicksand", "sans-serif";
}

.error-404 .error h2::before,
.empty-cart .error h2::before,
.no-order .error h2::before {
  content: "";
  width: 60px;
  bottom: calc(-11px + (-15 + 11) * ((100vw - 320px) / (1920 - 320)));
  height: 2px;
  display: block;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  background-color: #0baf9a;
}

.error-404 .error p,
.empty-cart .error p,
.no-order .error p {
  margin-bottom: calc(19px + (25 - 19) * ((100vw - 320px) / (1920 - 320)));
}

.error-404 .error .btn-solid,
.empty-cart .error .btn-solid,
.no-order .error .btn-solid {
  padding-top: calc(8px + (12 - 8) * ((100vw - 320px) / (1920 - 320)));
  padding-bottom: calc(8px + (12 - 8) * ((100vw - 320px) / (1920 - 320)));
}
